import React from 'react';
import { Box, Flex, Heading, Text, Button, Stack, Image } from '@chakra-ui/react';
import Img from "./svgviewer-png-output.png";
import Logo from "./logo.png";
import { FaGooglePlay, FaApple } from 'react-icons/fa';

function App() {
  return (
    <Flex
      direction="column"
      minH="100vh"
      justify="space-between" // Distributes space between header, content, and footer
    >
      {/* Main Header with Text Logo */}
      <Box bg="#442F8F" color="white">
        <Flex
          align="center"
          justify="space-between"
          maxW="1200px"
          mx="auto"
          py={6}
          px={3}
        >
          {/* Text Logo with smaller size */}
          <Flex align="center">
            <Heading as="h1" size="md" letterSpacing="wider" mr={4}>
              Plan Me Good
            </Heading>
            <Image 
              src={Logo} 
              alt="Logo" 
              maxW="40px"  // Adjust the size of the logo
            />
          </Flex>

          {/* Navigation Links */}
          <Stack direction="row" spacing={6}>
            <Button variant="link" colorScheme="whiteAlpha">
              Contact
            </Button>
            <Button variant="link" colorScheme="whiteAlpha">
              About
            </Button>
          </Stack>
        </Flex>
      </Box>

      {/* Main Content with Centered SVG Image */}
      <Flex direction="column" align="center" justify="center" mt={20} bg="white" pt={0}>
        <Box maxW="800px" textAlign="center">
          <Heading as="h1" size="2xl" mb={4} color="#442F8F">
            Launching soon on the Play Store, App Store, stay tuned for more updates!
          </Heading>
        </Box>
      </Flex>

      {/* Coming Soon Section */}
      <Flex
        bg="#f9f9f9"
        mt={20}
        py={10}
        px={10}
        align="center"
        justify="center"
      >
        <Flex
          width="80%"
          maxW="1200px"
          align="center"
          justify="space-between"
          direction={['column', 'row']}
          textAlign={['center', 'left']}
        >
          <Box flex="1" mb={[4, 0]} textAlign={['center', 'left']}>
            <Heading as="h2" size="xl" mb={4} color="#442F8F">
              Plan Me Good
            </Heading>
            <Text fontSize="lg" mb={6} color="#442F8F">
              Coming Soon on Play Store and App Store!
            </Text>
            <Stack direction="row" spacing={4} justify={['center', 'flex-start']}>
              <Button leftIcon={<FaGooglePlay />} colorScheme="teal" variant="solid" size="lg" bg="#FB774C">
                Play Store
              </Button>
              <Button leftIcon={<FaApple />} colorScheme="teal" variant="solid" size="lg" bg="#FB774C">
                App Store
              </Button>
            </Stack>
          </Box>
          <Box flex="1" textAlign="center">
            <Image 
              src={Img} 
              alt="Logo" 
              mx="auto" 
              borderRadius="md"  
              maxW="100%"        
            />
          </Box>
        </Flex>
      </Flex>

      {/* Sticky Footer */}
      <Box bg="#442F8F" color="white" py={6} mt="auto">
        <Flex
          direction="column"
          align="center"
          maxW="1200px"
          mx="auto"
          px={6}
        >
          <Text fontSize="md" mb={2}>
            &copy; {new Date().getFullYear()} PLAN ME GOOD. All rights reserved.
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default App;
